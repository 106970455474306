<template>
  <div>
    <Header :seet="number" />
    <el-row type="flex" class="account—settings">
      <el-col :span="3" style="width: 15.3%"></el-col>
      <el-col :span="17">
        <div class="settings_box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>账号中心</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goto()"
              >增票资质</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div style="display: flex">
            <div class="box_left">
              <LeftHurdle />
            </div>
            <div class="box_righted">
              <div class="box_right_top">{{ title }}</div>
              <div class="box_right_cent">
                <div class="ticket-box">
                  <div class="ticket-text">
                    1、填写资质信息
                  </div>
                  <el-form
                    :inline="true"
                    :model="form"
                    class="demo-form-inline"
                    :rules="rules"
                    ref="form"
                  >
                    <el-form-item
                      label-width="80px"
                      label="发票抬头"
                      prop="invoiceHeader"
                    >
                      <el-input v-model="form.invoiceHeader"></el-input>
                    </el-form-item>

                    <el-form-item
                      label-width="120px"
                      label="纳税人识别码"
                      prop="taxpayerNo"
                    >
                      <el-input v-model="form.taxpayerNo"></el-input>
                    </el-form-item>

                    <el-form-item
                      label-width="80px"
                      label="注册地址"
                      prop="registeredAddress"
                    >
                      <el-input v-model="form.registeredAddress"></el-input>
                    </el-form-item>

                    <el-form-item
                      label-width="120px"
                      label="注册电话"
                      prop="registeredPhone"
                    >
                      <el-input v-model="form.registeredPhone"></el-input>
                    </el-form-item>

                    <el-form-item
                      label-width="80px"
                      label="开户银行"
                      prop="bankName"
                    >
                      <el-input v-model="form.bankName"></el-input>
                    </el-form-item>

                    <el-form-item
                      label-width="120px"
                      label="银行帐号"
                      prop="bankAccount"
                    >
                      <el-input v-model="form.bankAccount"></el-input>
                    </el-form-item>
                  </el-form>
                  <!-- <div>
                            <div class="flex-start ticket-cent">
                                <div class="flex-start "> <span class="ticket-lefttext">发票抬头：</span> <el-input></el-input></div>
                                <div class="flex-start cent-box"> <span>纳税人识别码：</span><el-input></el-input></div>
                            </div>
                            <div class="flex-start ticket-cent">
                                <div class="flex-start"> <span class="ticket-lefttext">注册地址：</span> <el-input></el-input></div>
                                <div class="flex-start ticket-cent-box"> <span class="ticket-lefttext">注册电话：</span><el-input></el-input></div>
                            </div>
                            <div class="flex-start ticket-cent">
                                <div class="flex-start"> <span class="ticket-lefttext">开户银行：</span> <el-input></el-input></div>
                                <div class="flex-start ticket-cent-box"> <span class="ticket-lefttext">银行账号：</span><el-input></el-input></div>
                            </div>
                        </div> -->
                  <!-- <div class="ticket-text">
                    2、上传资质文件
                  </div>
                  <div>
                    注：上传文件均需加盖公章，支持的图片格式为jpg,jpeg,png,gif,bmp,pdf,务必上传清晰图片，单个图片不超过10M
                  </div>
                  <div class="ticket-copy">
                    <p style="margin-bottom: 15px">
                      <span>没有税务登记证副本</span>
                      <el-popover
                        placement="top-start"
                        title="如果没有虽无登记证副本，请您提供三证合一的营业执照扫描件："
                        width="420"
                        trigger="hover"
                        content="少数试点单位现在营业执照、企业机构代码证、税务登记证简化为一张证照、即指三证合一的营业执照。"
                      >
                        <i
                          slot="reference"
                          class="el-icon-question"
                          style="color: #ffcb01"
                        ></i>
                      </el-popover>
                    </p>
                    <el-upload
                      class="upload-demo"
                      action="/api/admin/bulk-purchase-invoice-config/uploadImaage"
                      :on-preview="handlePreview"
                      :on-success="handleSuccess"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :headers="headers"
                      multiple
                      :file-list="form.fileList"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                      <div slot="tip" class="el-upload__tip"></div>
                    </el-upload>
                    <p v-if="!form.fileList || form.fileList.length == 0">
                      未上传
                    </p> -->

                    <!-- 图片预览 -->
                    <!-- <el-image
                      ref="previewImg"
                      style="width: 100px; height: 100px"
                      :src="form.picturePreview"
                      fit="fill"
                      v-show="false"
                      :preview-src-list="[form.picturePreview]"
                    ></el-image>
                  </div> -->

                  <!-- <div class="ticket-copy">
                    <p style="margin-bottom: 15px">
                      <span>没有一般纳税人证书</span>
                      <el-popover
                        placement="top-start"
                        title="如果没有一般纳税人证书，请您提供以下证件中任何一个扫描件："
                        min-width="400"
                        trigger="hover"
                      >
                        1.盖有“增值税一般纳税人”章的税务登记证副本；<br />
                        2.一般纳税人年检通知书；<br />
                        3.增值税一般纳税人申请认定表；<br />
                        4.国家税务局增值税一般纳税人资格认定批复件；<br />
                        5.国税局颁发的相关的一般纳税人税务事项通知书；<br />
                        <i
                          slot="reference"
                          class="el-icon-question"
                          style="color: #ffcb01"
                        ></i>
                      </el-popover>
                    </p>
                    <el-upload
                      class="upload-demo"
                      action="/api/admin/bulk-purchase-invoice-config/uploadImaage"
                      :on-preview="handlePreview1"
                      :on-success="handleSuccess1"
                      :on-remove="handleRemove1"
                      :before-remove="beforeRemove1"
                      :headers="headers"
                      multiple
                      :file-list="form.fileList1"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                      <div slot="tip" class="el-upload__tip"></div>
                    </el-upload>
                    <p v-if="!form.fileList1 || form.fileList1.length == 0">
                      未上传
                    </p> -->

                    <!-- 图片预览 -->
                    <!-- <el-image
                      ref="previewImg1"
                      style="width: 100px; height: 100px"
                      :src="form.picturePreview1"
                      fit="fill"
                      v-show="false"
                      :preview-src-list="[form.picturePreview1]"
                    ></el-image>
                  </div> -->

                  <div class="ticket-btnbox flex-center">
                    <div
                      class="ticket-submit"
                      @click="commit()"
                      v-show="title != '查看增票资质'"
                    >
                      提交
                    </div>
                    <!-- <div
                                            class="ticket-cancel"
                                            @click="save()"
                                        >
                                            保存
                                        </div> -->
                    <div
                      class="ticket-cancel"
                      @click="$router.go(-1)"
                      v-show="title != '查看增票资质'"
                    >
                      取消
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="width: 15.5%"></el-col>
    </el-row>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";
import { saveEntity, getEntity, updateEntity } from "@/api/invoice/invoice";

export default {
  components: { Header, Footer, LeftHurdle },
  data() {
    var bankAccountValid = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("银行卡号不能为空"));
      } else if (!Number.isInteger(+value)) {
        callback(new Error("银行卡号必须全为数字"));
      } else if (value.trim().length < 6 || value.trim().length > 100) {
        callback(new Error("银行卡号长度必须在12到100之间"));
      } else {
        callback();
      }
    };
    return {
      title: "添加增票资质",
      form: {
        id: "", //id
        invoiceHeader: "", //发票抬头
        taxpayerNo: "", //纳税人识别码
        registeredAddress: "", //注册地址
        registeredPhone: "", //	注册电话
        bankName: "", //开户银行
        bankAccount: "", //银行账号
        picturePreview: "", //税务登记证副本图片预览
        picturePreview1: "", //一般纳税人文件图片预览
        fileList: [], //税务登记证副本
        fileList1: [] //一般纳税人文件
      },

      number: "4",
      ruleForm: {
        name: ""
      },
      rules: {
        invoiceHeader: [
          {
            required: true,
            message: "请输入发票抬头",
            trigger: "blur"
          }
        ],
        taxpayerNo: [
          {
            required: true,
            message: "请输入纳税人识别码",
            trigger: "blur"
          },
          { pattern: /^[0-9A-Z]*$/, message: "请输入大写英文数字的识别码" }
        ],
        registeredAddress: [
          {
            required: true,
            message: "请输入注册地址",
            trigger: "blur"
          }
        ],
        registeredPhone: [
          {
            required: true,
            message: "请输入注册电话",
            trigger: "blur"
          }
        ],
        bankName: [
          {
            required: true,
            message: "请输入开户银行",
            trigger: "blur"
          }
        ],
        bankAccount: [
          {
            required: true,
            validator: bankAccountValid,
            trigger: "blur"
          }
        ]
      },
      headers: { Authorization: localStorage.getItem("token") }
    };
  },
  mounted() {
    // let form = window.localStorage.getItem("addTicketForm");
    // if (form) {
    //     this.form = JSON.parse(form);
    // }
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getEntity(this.form.id);
    }
    if (this.$route.query.type == "edit") {
      this.title = "修改增票资质";
    }
    if (this.$route.query.type == "view") {
      this.title = "查看增票资质";
    }
  },
  methods: {
    getEntity(id) {
      getEntity(id).then(res => {
        if (res.code == "00000") {
          this.form = res.data;
          if (res.data.taxRegistration) {
            let arr = res.data.taxRegistration.split(",");
            this.form.fileList = [{ name: arr[0], url: arr[0] }];
            this.form.fileList1 = [{ name: arr[1], url: arr[1] }];
          }
        }
      });
    },
    saveEntity() {
      // let str = this.form.fileList[0].url;
      // if (str) {
      //   str += "," + this.form.fileList1[0].url;
      // } else {
      //   str = this.form.fileList1[0].url;
      // }&taxRegistration=${str}
      let state = 1;
      let data = `bankAccount=${this.form.bankAccount}&bankName=${this.form.bankName}&invoiceHeader=${this.form.invoiceHeader}&registeredAddress=${this.form.registeredAddress}&registeredPhone=${this.form.registeredPhone}&taxpayerNo=${this.form.taxpayerNo}`;
      if (this.form.id) {
        state = 0;
        data += "&id=" + this.form.id;
        data += "&state=" + state;
        updateEntity(data).then(res => {
          if (res.code == "00000") {
            this.$message({
              message: "提交成功！",
              type: "success"
            });
            setTimeout(() => {
              this.$router.push({
                name: "AddedQualification"
              });
            }, 1000);
          } else {
            this.$message.error(res.desc);
          }
        });
      } else {
        data += "&state=" + state;
        saveEntity(data).then(res => {
          if (res.code == "00000") {
            this.$message({
              message: "提交成功！",
              type: "success"
            });
            setTimeout(() => {
              this.$router.push({
                name: "AddedQualification"
              });
            }, 1000);
          } else {
            this.$message.error(res.desc);
          }
        });
      }

      // saveEntity(data).then((res) => {
      //     console.log(res, "res");
      // });
    },
    //保存到本地
    save() {
      window.localStorage.setItem("addTicketForm", JSON.stringify(this.form));
      this.$message({
        message: "保存成功！",
        type: "success"
      });
    },
    //提交
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          //   console.log(this.form, "this.form");
          //   if (!this.form.fileList || this.form.fileList.length == 0) {
          //     this.$message.error("请上税务登记证副本！");
          //     return;
          //   }
          //   if (!this.form.fileList1 || this.form.fileList1.length == 0) {
          //     this.$message.error("请上传一般纳税人证书！");
          //     return;
          //   }
          this.saveEntity({ state: 1 });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // handleSuccess(file, fileList) {
    //   console.log(file, fileList);
    //   if (file.status == "success" || file.code == "00000") {
    //     this.$set(this.form, "fileList", [
    //       { name: fileList.name, url: file.data }
    //     ]);
    //     this.$set(this.form, "picturePreview", file.data);
    //   } else {
    //     this.$message.error(file.desc);
    //     this.handleRemove();
    //   }
    //   console.log(this.form, "form");
    // },
    // handleSuccess1(file, fileList) {
    //   console.log(file, fileList);
    //   if (file.status == "success" || file.code == "00000") {
    //     this.$set(this.form, "fileList1", [
    //       { name: fileList.name, url: file.data }
    //     ]);
    //     this.$set(this.form, "picturePreview1", file.data);
    //   } else {
    //     this.$message.error(file.desc);
    //     this.handleRemove1();
    //   }
    //   console.log(this.form, "form");
    // },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    //   this.$set(this.form, "fileList", []);
    //   this.$set(this.form, "picturePreview", "");
    //   // this.$message({
    //   //     type: "success",
    //   //     message: "删除成功!",
    //   // });
    // },
    // handleRemove1(file, fileList) {
    //   console.log(file, fileList);
    //   this.$set(this.form, "fileList1", []);
    //   this.$set(this.form, "picturePreview1", "");
    //   // this.$message({
    //   //     type: "success",
    //   //     message: "删除成功!",
    //   // });
    // },
    // handlePreview(file) {
    //   console.log(file, "file");
    //   if (
    //     file.status == "success" ||
    //     file.state == "success" ||
    //     file.response.code == "00000"
    //   ) {
    //     this.$set(this.form, "picturePreview", file.url || file.response.data);
    //     this.$refs.previewImg.clickHandler();
    //   } else {
    //     this.$message.error(file.response.desc);
    //   }
    // },
    // handlePreview1(file) {
    //   console.log(file, "file");
    //   if (
    //     file.status == "success" ||
    //     file.state == "success" ||
    //     file.response.code == "00000"
    //   ) {
    //     // this.form.picturePreview1 = file.url || file.response.data;
    //     this.$set(this.form, "picturePreview1", file.url || file.response.data);
    //     this.$refs.previewImg1.clickHandler();
    //   } else {
    //     this.$message.error(file.response.desc);
    //   }
    // },
    // beforeRemove(file) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },
    // beforeRemove1(file) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },
    goto() {
      this.$router.push({ name: "AddedQualification" });
    }
  }
};
</script>

<style lang="scss">
.el-popover {
  color: rgba($color: #000000, $alpha: 0.7) !important;
  font-size: 14px;
}
.el-popover__title {
  color: rgba($color: #000000, $alpha: 0.7) !important;
  font-size: 14px;
  font-weight: bold;
}
.settings_box {
  margin: 30px 0;
  .el-breadcrumb {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left {
    margin-right: 20px;
  }
  .box_righted {
    width: 87%;
    // min-height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top {
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .ticket-box {
      padding: 20px;
      .cent-box {
        margin-left: 70px;
      }
      .ticket-cent-box {
        margin-left: 110px;
      }
      .ticket-text {
        font-weight: bold;
        margin-bottom: 20px;
      }
      .ticket-cent {
        margin-bottom: 15px;
        span {
          width: 190px;
        }
        .ticket-lefttext {
          width: 120px;
        }
      }
      .ticket-copy {
        margin: 20px 0;
        .copy-none {
          color: #cd9f49;
        }
        .copy-btn {
          margin: 0 15px;
          padding: 2px 10px;
          color: #cd9f49;
          font-size: 15px;
          border: 1px solid #cd9f49;
        }
      }
      .ticket-btnbox {
        margin-top: 15%;
        .ticket-submit {
          width: 50px;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          color: #fff;
          background: #cd9f49;
          text-align: center;
          border-radius: 5px;
          margin-right: 15px;
        }
        .ticket-cancel {
          width: 50px;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          border: 1px solid #999;
          color: #999;
          text-align: center;
          border-radius: 5px;
          margin: 0 15px;
        }
      }
    }
  }
}
</style>

import request from '@/utils/request'
/**
 * 查询增值发票列表
 * @param data
 */
export function queryListAndPage(data) {
    return request({
        url: 'admin/bulk-purchase-invoice-config/queryPage',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        data
    })
}

/**
 * 添加增值发票
 * @param data
 */
export function saveEntity(data) {
    return request({
        url: 'admin/bulk-purchase-invoice-config/saveEntity',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        data
    })
}

/**
 * 删除增值发票
 * @param data
 */
export function deleteInvoiceById(data) {
    return request({
        url: 'admin/bulk-purchase-invoice-config/deleteInvoiceById/'+data,
        method: 'post',
    })
}

/**
 * 获取发票数据
 * @param data
 */
export function getEntity(data) {
    return request({
        url: 'admin/bulk-purchase-invoice-config/getEntity/'+data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
    })
}

/**
 * 修改发票数据
 * @param data
 */
export function updateEntity(data) {
    return request({
        url: 'admin/bulk-purchase-invoice-config/updateEntity',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        data
    })
}
